<template>
  <div id="user_profile">
    <!-- <v-row>
      <v-col xs="6" md="6">
        <p class="main-color main-header">User Profile</p>
      </v-col> 
      <v-col xs="6" md="6" class="edit-icon">
        <v-icon class="" @click="editProfileData" v-if="!editable">edit</v-icon>
      </v-col>
    </v-row>-->
    <form class @submit.prevent="submitProfileDate()">
      <v-row style="padding-top: 40px">
        <v-col cols="12" class="first-info">
          <v-card class="mx-auto" shaped>
            <v-card-text style="padding: 0 !important">
              <v-row
                class="lighten-5"
                style="margin: 0 !important; padding: 0 !important"
                justify="center"
              >
                <!-- <v-col cols="6">
                      <h3 id="info-header" class="info-header">
                        General Information
                      </h3>
                    </v-col>
                    <v-col xs="6" md="6" class="edit-icon">
                      <v-icon class @click="editProfileData" v-if="!editable"
                        >edit</v-icon
                      >
                    </v-col> -->
                <v-col
                  cols="12"
                  sm="4"
                  class="text-center main-color-background"
                  v-if="!editable"
                >
                  <div class="logo-div-img">
                    <img
                      style="width: 80px; height: 80px"
                      v-if="profileData.logo != null"
                      id="logo"
                      class="rounded-circle"
                      :src="profileData.logo"
                      alt
                    />
                    <img
                      v-if="profileData.logo == null"
                      id="logo"
                      class="rounded-circle"
                      src="../../assets/avatar.png"
                    />
                  </div>
                  <v-spacer></v-spacer>
                  <br />
                  <p class="dark-color">{{ profileData.name }}</p>
                  <p class="dark-color">{{ profileData.role }}</p>
                  <br />
                  <v-btn
                    rounded
                    :class="generalInformation ? 'TabBtnActive' : 'TabBtn'"
                    @click="openGeneralInformation"
                  >
                    <v-icon> person </v-icon>
                    {{ $t("General Information") }}</v-btn
                  >
                  <br />
                  <v-btn
                    rounded
                    :class="notificationSetting ? 'TabBtnActive' : 'TabBtn'"
                    @click="openNotificationSetting"
                  >
                    <v-icon> notification_important </v-icon>
                    {{ $t("Notification Settings") }}</v-btn
                  >
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                  class="text-center main-color-background"
                  id="logo-div"
                  v-if="editable && generalInformation"
                >
                  <v-row>
                    <!-- <v-col xs="12" md="12">
                          <p><strong>Upload Photo: </strong></p>
                        </v-col>-->
                    <v-col xs="12" md="12">
                      <!-- <input
                            type="file"
                            @change="onFileSelected"
                            class="form-control-file"
                          />-->
                      <div class="custom-file" id="upload-photo">
                        <input
                          type="file"
                          class="custom-file-input"
                          @change="onFileSelected"
                        />

                        <label class="custom-file-label" for="customFile">
                          <br />
                        </label>
                      </div>
                      <img
                        v-if="profileDataEdit.logo != null"
                        :src="profileDataEdit.logo"
                        class="mg-t-30"
                      />
                      <img
                        v-if="profileDataEdit.logo == null"
                        class="mg-t-30"
                        src="../../assets/avatar.png"
                      />
                      <img
                        src="../../assets/img/preloader.gif"
                        id="preloader-custom"
                        alt="preloader"
                        class="hidden"
                      />

                      <div v-if="true">
                        <p class="error mg-t-30">{{ logoError }}</p>
                      </div>
                      <v-spacer></v-spacer>
                      <br />
                      <v-autocomplete
                        v-if="canEditRole"
                        :label="$i18n.t('Role')"
                        :items="roles"
                        class="select"
                        @change="hideLabel = true"
                        item-text="display_name"
                        item-value="id"
                        multiple
                        v-model="profileDataEdit.roles_ids"
                        solo
                      ></v-autocomplete>
                      <div v-if="validation_errors.roles_ids">
                        <p
                          class="red--text"
                          v-for="(error, index) in validation_errors.roles_ids"
                          :key="index"
                        >
                          {{ error }}
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- Notification Settings -->
                <v-col
                  cols="12"
                  sm="8"
                  id="info-div"
                  class="info-div"
                  v-if="notificationSetting && !editable"
                >
                  <v-row>
                    <v-col cols="12">
                      <v-alert
                        border="right"
                        colored-border
                        type="error"
                        elevation="2"
                        v-if="check_email == false"
                      >
                        {{
                          $t(
                            "Please Provide an Email to activate Email notifications"
                          )
                        }}
                      </v-alert>
                      <v-alert
                        border="right"
                        colored-border
                        type="error"
                        elevation="2"
                        v-if="check_mobile == false"
                      >
                        {{ $t("Please Provide mobile number to activate SMS") }}
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <p class="mt-2 mb-0">
                        <strong>
                          {{ $t("Prefered Language") }}
                        </strong>
                      </p>
                    </v-col>
                    <v-col>
                      <v-radio-group
                        v-model="selectedLang"
                        @change="saveLang"
                        row
                      >
                        <v-radio :label="$t('English')" value="en"></v-radio>
                        <v-radio :label="$t('Arabic')" value="ar"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-data-table
                    :loading="loadingNotificationTable"
                    loading-text="Loading... Please wait"
                    :headers="headers"
                    :items="items"
                    item-key="id"
                    :show-select="false"
                    class="elevation-1 level1"
                    hide-default-footer
                    :options.sync="options"
                  >
                    <!-- datatable custom header on datatable -->
                    <template
                      v-for="(h, index) in headers"
                      v-slot:[`header.${h.value}`]="{ header }"
                    >
                      <div
                        :key="index"
                        v-if="h.text != 'Name'"
                        class="d-flex align-center"
                      >
                        <v-checkbox
                          v-model="bulkEmail"
                          v-if="h.text == 'Email'"
                          @change="bulkChangeStatus(items, 'email')"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="bulkSms"
                          v-if="h.text == 'SMS'"
                          @change="bulkChangeStatus(items, 'sms')"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="bulkNotification"
                          v-if="h.text == 'Push Notifications'"
                          @change="bulkChangeStatus(items, 'notification')"
                        ></v-checkbox>
                        <span> {{ h.text }} </span>
                      </div>
                    </template>

                    <template v-slot:item.email="{ item }">
                      <v-switch
                        v-model="item.email"
                        style="text-align: center !important"
                        @change="changeNotificationStatus(item, 'email')"
                        :disabled="!check_email"
                      ></v-switch>
                    </template>
                    <template v-slot:item.sms="{ item }">
                      <v-switch
                        v-model="item.sms"
                        style="text-align: center !important"
                        @change="changeNotificationStatus(item, 'sms')"
                        :disabled="!check_mobile"
                      ></v-switch>
                    </template>
                    <template v-slot:item.Notification="{ item }">
                      <v-switch
                        v-model="item.Notification"
                        style="text-align: center !important"
                        @change="changeNotificationStatus(item, 'notification')"
                      ></v-switch>
                    </template>
                  </v-data-table>
                  <!-- ======================================== Pagination ======================================  -->
                  <div class="text-center pt-2">
                    <v-pagination
                      v-if="length > 0 && totalItems > 0"
                      v-model="page"
                      :length="length"
                      :circle="circle"
                      :page="page"
                      :total-visible="totalVisible"
                      next-icon="mdi-menu-right"
                      prev-icon="mdi-menu-left"
                    ></v-pagination>
                  </div>
                </v-col>
                <!-- End Notification Settings -->
                <v-col
                  cols="12"
                  sm="8"
                  id="info-div"
                  class="info-div"
                  v-if="generalInformation"
                >
                  <!-- ====================================== show data view==================================== -->
                  <div class="viewData" v-if="!editable && generalInformation">
                    <v-row>
                      <v-col xs="12" md="12" class="edit-icon">
                        <v-icon
                          class
                          @click="editProfileData"
                          v-if="!editable"
                          :title="$t('Edit')"
                          >edit</v-icon
                        >
                      </v-col>
                    </v-row>
                    <!-- <v-row>
                      <v-col xs="3" md="3">
                        <p>
                          <strong>Name:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" md="9" class>{{ profileData.name }}</v-col>
                    </v-row> -->
                    <v-row>
                      <v-col xs="3" md="3">
                        <p>
                          <strong>{{ $t("Mobile") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" md="9" class
                        >{{ profileData.code }}{{ profileData.mobile }}</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col xs="3" md="3">
                        <p>
                          <strong>{{ $t("Email") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" md="9">{{ profileData.email }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col xs="3" md="3">
                        <p>
                          <strong>{{ $t("Country") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" class md="9">{{
                        profileData.country
                      }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col xs="3" md="3">
                        <p>
                          <strong>{{ $t("Gender") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" class md="9">{{
                        profileData.gender
                      }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col xs="3" md="3">
                        <p>
                          <strong>{{ $t("Date Of Birth") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" class md="9">{{
                        profileData.date_of_birth
                      }}</v-col>
                    </v-row>
                    <v-row v-if="teacher">
                      <v-col xs="3" md="3">
                        <p>
                          <strong>{{ $t("Subject") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" class md="9">{{
                        profileData.subjects
                      }}</v-col>
                    </v-row>
                    <v-row v-if="teacher">
                      <v-col xs="3" md="3">
                        <p>
                          <strong>{{ $t("Grade") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" class md="9">{{
                        profileData.grades
                      }}</v-col>
                    </v-row>
                    <v-row v-if="!changePassword">
                      <v-col xs="3" md="3">
                        <p>
                          <strong>{{ $t("Password") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" class md="4">
                        <p v-if="profileData.show_password">
                          {{ profileData.plain_password }}
                        </p>
                        <p v-else>********</p>
                        <br />
                        <a class="main-color" @click="changePasswordDialog">{{
                          $t("Change Password?")
                        }}</a>
                      </v-col>
                      <v-col xs="9" md="5">
                        <v-icon
                          class="show-password-icon"
                          v-if="!profileData.show_password"
                          @click="profileData.show_password = true"
                          :title="$t('Show Password')"
                          >mdi-eye</v-icon
                        >
                        <v-icon
                          class="show-password-icon"
                          v-if="profileData.show_password"
                          @click="profileData.show_password = false"
                          :title="$t('Hide Password')"
                          >mdi-eye-off</v-icon
                        >
                      </v-col>
                    </v-row>
                    <v-row v-if="changePassword" class="change_password_div">
                      <v-col xs="3" md="3" class="old_password user_profile">
                        <p class="label">
                          <strong>{{ $t("Old Password") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" md="9" class="old_password user_profile">
                        <v-text-field
                          v-model.trim="password.old_password"
                          solo
                          @focus="onFocus()"
                          :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                          @click:append="() => (value = !value)"
                          :type="value ? 'password' : 'text'"
                        ></v-text-field>

                        <div v-if="validation_errors_password.old_password">
                          <p
                            class="red--text"
                            v-for="(error,
                            index) in validation_errors_password.old_password"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                      <v-col xs="3" md="3" class="user_profile">
                        <p class="label">
                          <strong>{{ $t("New Password") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" md="9" class="user_profile">
                        <v-text-field
                          v-model.trim="password.new_password"
                          solo
                          @focus="onFocus()"
                          :append-icon="value1 ? 'mdi-eye-off' : 'mdi-eye'"
                          @click:append="() => (value1 = !value1)"
                          :type="value1 ? 'password' : 'text'"
                        ></v-text-field>

                        <div v-if="validation_errors_password.new_password">
                          <p
                            class="red--text"
                            v-for="(error,
                            index) in validation_errors_password.new_password"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                      <v-col xs="3" md="3" class="user_profile">
                        <p class="label">
                          <strong>{{ $t("Confirm Password") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" md="9" class="user_profile">
                        <v-text-field
                          v-model.trim="password.confirm_password"
                          solo
                          @focus="onFocus()"
                          type="password"
                          @paste.prevent
                        ></v-text-field>

                        <div v-if="validation_errors_password.confirm_password">
                          <p
                            class="red--text"
                            v-for="(error,
                            index) in validation_errors_password.confirm_password"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        justify="right"
                        align="right"
                        class="submit-div-password user_profile"
                      >
                        <button class="ui-btn cancel" @click="cancelPassword">
                          {{ $t("Cancel") }}
                        </button>

                        <button
                          @click="savePassword"
                          class="ui-btn submit"
                          :loading="loading"
                          :disabled="loading"
                        >
                          {{ $t("Save") }}
                        </button>
                      </v-col>
                    </v-row>
                  </div>

                  <!-- ========================================== edit data view ======================================= -->
                  <div class="editData" v-if="editable && generalInformation">
                    <v-row>
                      <v-col xs="3" md="3">
                        <p class="label">
                          <strong>{{ $t("First Name") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" class md="9">
                        <v-text-field
                          v-model.trim="$v.profileDataEdit.first_name.$model"
                          solo
                          @focus="onFocus()"
                        ></v-text-field>
                        <p
                          class="red--text"
                          v-if="
                            $v.profileDataEdit.first_name.$error &&
                              !$v.profileDataEdit.first_name.required
                          "
                        >
                          {{ $t("This field is required") }}
                        </p>
                        <p
                          class="red--text"
                          v-if="!$v.profileDataEdit.first_name.maxLength"
                        >
                          {{
                            $t(
                              "You have reached your maximum limit of characters allowed"
                            )
                          }}
                        </p>
                        <div v-if="validation_errors.first_name">
                          <p
                            class="red--text"
                            v-for="(error,
                            index) in validation_errors.first_name"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="space">
                      <v-col xs="3" md="3">
                        <p class="label">
                          <strong>{{ $t("Last Name") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" class md="9">
                        <v-text-field
                          id
                          v-model.trim="$v.profileDataEdit.last_name.$model"
                          solo
                          @focus="onFocus()"
                        ></v-text-field>
                        <p
                          class="red--text"
                          v-if="
                            $v.profileDataEdit.last_name.$error &&
                              !$v.profileDataEdit.last_name.required
                          "
                        >
                          {{ $t("This field is required") }}
                        </p>
                        <p
                          class="red--text"
                          v-if="!$v.profileDataEdit.last_name.maxLength"
                        >
                          {{
                            $t(
                              "You have reached your maximum limit of characters allowed"
                            )
                          }}
                        </p>
                        <div v-if="validation_errors.last_name">
                          <p
                            class="red--text"
                            v-for="(error,
                            index) in validation_errors.last_name"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="space">
                      <v-col xs="3" md="3">
                        <p class="label">
                          <strong>{{ $t("Mobile") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="3" md="3">
                        <div class="code">
                          <v-autocomplete
                            :items="Codes"
                            class="select"
                            @change="hideLabel = true"
                            item-text="title"
                            item-value="value"
                            v-model="$v.profileDataEdit.country_code_id.$model"
                            solo
                            @focus="onFocus()"
                          >
                            <template slot="selection" slot-scope="data">
                              <!-- <img src="../../assets/flags/AF.png" />
                                  {{ data.title }}-->

                              <v-flex xs3>
                                <v-avatar size="25">
                                  <img :src="data.item.icon" />
                                </v-avatar>
                              </v-flex>
                              <v-flex class="ml-3">{{
                                data.item.title
                              }}</v-flex>
                            </template>
                            <template slot="item" slot-scope="data">
                              <v-list-item-avatar
                                style="
                                  width: 20px;
                                  min-width: 20px;
                                  height: 20px;
                                "
                              >
                                <img :src="data.item.icon" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="data.item.title"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </div>
                      </v-col>
                      <v-col xs="6" md="6">
                        <v-text-field
                          v-if="isStudent"
                          v-model.trim="profileDataEdit.mobile"
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <v-text-field
                          v-else
                          v-model.trim="$v.profileDataEdit.mobile.$model"
                          @focus="onFocus()"
                          solo
                        ></v-text-field>

                        <p
                          class="red--text"
                          v-if="
                            !isStudent &&
                              $v.profileDataEdit.mobile.$error &&
                              !$v.profileDataEdit.mobile.required
                          "
                        >
                          {{ $t("This field is required") }}
                        </p>
                        <p
                          class="red--text"
                          v-if="
                            !isStudent && !$v.profileDataEdit.mobile.maxLength
                          "
                        >
                          {{
                            $t(
                              "You have reached your maximum limit of characters allowed"
                            )
                          }}
                        </p>
                        <div v-if="validation_errors.mobile">
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors.mobile"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="space">
                      <v-col xs="3" md="3">
                        <p class="label">
                          <strong>{{ $t("Email") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" class md="9">
                        <v-text-field
                          id
                          v-model.trim="$v.profileDataEdit.email.$model"
                          solo
                          @focus="onFocus()"
                        ></v-text-field>
                        <p
                          class="red--text"
                          v-if="
                            $v.profileDataEdit.email.$error &&
                              !$v.profileDataEdit.email.required
                          "
                        >
                          {{ $t("This field is required") }}
                        </p>
                        <p
                          class="red--text"
                          v-if="!$v.profileDataEdit.email.maxLength"
                        >
                          {{
                            $t(
                              "You have reached your maximum limit of characters allowed"
                            )
                          }}
                        </p>
                        <div v-if="validation_errors.email">
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors.email"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="space">
                      <v-col xs="3" md="3">
                        <p class="label">
                          <strong>{{ $t("Country") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" class md="9">
                        <v-autocomplete
                          :items="nationalities"
                          class="select"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          v-model="$v.profileDataEdit.country_id.$model"
                          solo
                          @focus="onFocus()"
                        ></v-autocomplete>

                        <p
                          class="red--text"
                          v-if="!$v.profileDataEdit.country_id.required"
                        >
                          {{ $t("This field is required") }}
                        </p>

                        <div v-if="validation_errors.country_id">
                          <p
                            class="red--text"
                            v-for="(error,
                            index) in validation_errors.country_id"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="space">
                      <v-col xs="3" md="3">
                        <p class="label">
                          <strong>{{ $t("Gender") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" class md="9">
                        <div class="checkbox">
                          <v-row justify="start">
                            <v-checkbox
                              class="mx-2"
                              :label="$t('Male')"
                              id="male"
                              value="male"
                              v-model.trim="$v.profileDataEdit.gender.$model"
                              @focus="onFocus()"
                            ></v-checkbox>
                            <v-checkbox
                              class="mx-2"
                              :label="$t('Female')"
                              id="female"
                              value="female"
                              v-model.trim="$v.profileDataEdit.gender.$model"
                              @focus="onFocus()"
                            ></v-checkbox>
                          </v-row>
                        </div>

                        <div v-if="validation_errors.gender">
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors.gender"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="space">
                      <v-col xs="3" md="3">
                        <p class="label">
                          <strong>{{ $t("Date of Birth") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="9" class md="9">
                        <v-text-field
                          id="gregorian"
                          @focus="onFocus()"
                          v-model.lazy="$v.profileDataEdit.date_of_birth.$model"
                          solo
                        ></v-text-field>

                        <div v-if="validation_errors.date_of_birth">
                          <p
                            class="red--text"
                            v-for="(error,
                            index) in validation_errors.date_of_birth"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="editable">
        <v-col
          cols="12"
          justify="center"
          align="center"
          class="submit-div text-center"
        >
          <button
            id="submit"
            type="submit"
            class="ui-btn cancel"
            @click="cancel"
          >
            {{ $t("Cancel") }}
          </button>

          <button id="submit" type="submit" class="ui-btn submit">
            {{ $t("Save") }}
          </button>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="snack"
        :timeout="snackTime"
        :color="snackColor"
        :right="true"
        :top="true"
      >
        <!-- {{ snackText }} -->

        <span class="white--text">{{ snackText }}</span>
        <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
      </v-snackbar>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import ACL from "../../acl";
import { ProfileMixin } from "../../mixins/ProfileMixin";

export default {
  name: "Profile",
  mixins: [ProfileMixin],
  data() {
    return {
      check_mobile: true,
      check_email: true,
      selectedLang: "",

      isStudent: ACL.Role("Student"),
      /** Notification table data */
      options: {
        itemsPerPage: 15
      },
      loadingNotificationTable: true,
      items: [],
      headers: [
        {
          text: this.$i18n.t("Name"),
          value: "event_name",
          sortable: false
        },
        {
          text: this.$i18n.t("Email"),
          value: "email",
          sortable: false
        },
        {
          text: this.$i18n.t("SMS"),
          value: "sms",
          sortable: false
        },
        {
          text: this.$i18n.t("Push Notifications"),
          value: "Notification",
          sortable: false
        }
      ],
      /**pagination */
      circle: true,
      page: 1,
      teacher: ACL.Role("Teacher"),
      length: "",
      totalVisible: "",
      totalItems: "",
      /** end notification table data */
      generalInformation: true,
      notificationSetting: false,
      /***password change */
      value: true,
      value1: true,
      loading: false,
      changePassword: false,
      password: {
        old_password: "",
        new_password: "",
        confirm_password: ""
      },
      validation_errors_password: {
        old_password: "",
        new_password: "",
        confirm_password: ""
      },
      /**end password change */
      profileData: {
        id: "",
        name: "",
        email: "",
        mobile: "",
        first_name: "",
        last_name: "",
        gender: "",
        date_of_birth: "",
        country_id: "",
        country_code_id: "",
        country: "",
        plain_password: "",
        show_password: "",
        subjects: "",
        grades: ""
      },
      profileDataEdit: {},
      error: false,
      successMsg: "",
      validation_errors: [],
      selectedFile: null,
      logo: "",
      logoError: "",
      otherLangSelected: false,
      submitted: false,
      backendUrl: this.$store.state.backendUrl,
      editable: false,
      nationalities: [],
      Codes: [],
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 7000,
      roles: [],
      canEditRole: ACL.checkPermission("edit-users-role"),
      bulkEmail: true,
      bulkNotification: true,
      bulkSms: true
    };
  },
  watch: {
    page: {
      handler() {
        this.getUserNotification();
      }
    }
  },

  methods: {
    changeNotificationStatus(item, type) {
      /* check if it's all selected or not sms - email - notification */
      if (type == "email") {
        let bulkEmailCheck = true;
        this.items.forEach(item => {
          if (item.email == false) {
            bulkEmailCheck = false;
          }
        });

        bulkEmailCheck == false
          ? (this.bulkEmail = false)
          : (this.bulkEmail = true);
      }
      if (type == "sms") {
        let bulkSmsCheck = true;
        this.items.forEach(item => {
          if (item.sms == false) {
            bulkSmsCheck = false;
          }
        });

        bulkSmsCheck == false ? (this.bulkSms = false) : (this.bulkSms = true);
      }
      if ((type = "notification")) {
        let bulkNotificationCheck = true;
        this.items.forEach(item => {
          if (item.Notification == false) {
            bulkNotificationCheck = false;
          }
        });

        bulkNotificationCheck == false
          ? (this.bulkNotification = false)
          : (this.bulkNotification = true);
      }
      axios
        .post(this.getApiUrl + "/users/savetUserNotificationStatus", item, {
          headers: {
            Authorization: "Bearer " + localStorage.token
            //the token is a variable which holds the token
          }
        })
        .then(response => {
          if (response.data.status.error == false) {
            this.snack = true;
            this.snackColor = "green";
            this.snackText = "Saved";
            return true;
          } else {
            return false;
          }
        });
    },
    bulkChangeStatus(items, type) {
      if (type == "email") {
        if (this.bulkEmail) {
          this.items.forEach(item => {
            item.email = true;
          });
        } else {
          this.items.forEach(item => {
            item.email = false;
          });
        }
      } else if (type == "notification") {
        if (this.bulkNotification) {
          this.items.forEach(item => {
            item.Notification = true;
          });
        } else {
          this.items.forEach(item => {
            item.Notification = false;
          });
        }
      } else if (type == "sms") {
        if (this.bulkSms) {
          this.items.forEach(item => {
            item.sms = true;
          });
        } else {
          this.items.forEach(item => {
            item.sms = false;
          });
        }
      }
      axios
        .post(this.getApiUrl + "/users/bulkSaveUserNotificationStatus", items, {
          headers: {
            Authorization: "Bearer " + localStorage.token
            //the token is a variable which holds the token
          }
        })
        .then(response => {
          if (response.data.status.error == false) {
            this.snack = true;
            this.snackColor = "green";
            this.snackText = "Saved";
          } else {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = "Error on saving data";
          }
        });
    },
    saveLang() {
      axios
        .post(
          this.getApiUrl + "/users/changePnPreferredLanguage",
          { pn_preferred_language: this.selectedLang },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
              //the token is a variable which holds the token
            }
          }
        )
        .then(response => {
          if (response.data.status.error == false) this.snack = true;
          this.snackColor = "green";
          this.snackText = "Saved";
        });
    },
    openGeneralInformation() {
      this.generalInformation = true;
      this.notificationSetting = false;
    },
    openNotificationSetting() {
      this.generalInformation = false;
      this.notificationSetting = true;
    },
    cancelPassword() {
      this.changePassword = false;
      this.validation_errors_password = {
        old_password: "",
        new_password: "",
        confirm_password: ""
      };
      this.password = {
        old_password: "",
        new_password: "",
        confirm_password: ""
      };
      this.value = true;
      this.value1 = true;
    },
    savePassword() {
      this.loading = true;
      axios
        .post(this.getApiUrl + "/users/changePasswordProfile", this.password, {
          headers: {
            Authorization: "Bearer " + localStorage.token
            //the token is a variable which holds the token
          }
        })
        .then(response => {
          if (response.data.status.error) {
            this.validation_errors_password =
              response.data.status.validation_errors;
          } else {
            this.snack = true;
            this.snackColor = "green";
            this.snackText = response.data.status.message;
            this.cancelPassword();
          }
          this.loading = false;
        });
    },
    changePasswordDialog() {
      this.changePassword = true;
    },
    editProfileData() {
      this.editable = true;
      var _this = this;
      $(document).ready(function() {
        $("#gregorian").calendarsPicker({
          maxDate: 0,
          onSelect: function(date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.profileDataEdit.date_of_birth = dateH;
            } else {
              _this.profileDataEdit.date_of_birth = "";
            }
          }
        });
      });
    },
    submitProfileDate() {
      this.getData(true);
      this.$v.$reset();
    },
    onFileSelected(event) {
      document.getElementById("preloader-custom").classList.remove("hidden");
      const fd = new FormData();
      this.selectedFile = event.target.files[0];
      fd.append("image", this.selectedFile);
      axios
        .post(this.getApiUrl + "/uploadFormImage", fd, {
          headers: {
            Authorization: "Bearer " + localStorage.token
            //the token is a variable which holds the token
          }
        })
        .then(response => {
          if (response.data.status.error) {
            this.logoError = response.data.status.validation_errors.image[0];
          } else {
            this.profileDataEdit.logo = response.data.data.url;
            localStorage.user_logo = response.data.data.url;
          }
          document.getElementById("preloader-custom").classList.add("hidden");
        });
    },
    onFocus() {
      this.error = false;
      this.validation_errors = [];
    },
    getUserNotification() {
      axios
        .get(
          this.getApiUrl + "/users/getUserNotificationStatus?page=" + this.page,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
              //the token is a variable which holds the token
            }
          }
        )
        .then(response => {
          if (response.data.status.error == false) {
            this.loadingNotificationTable = false;
            this.setPaginationParameters(response);
            this.items = [];
            this.items = response.data.data.events.data;
            this.selectedLang = response.data.data.pn_preferred_language;
            this.check_email = response.data.data.check_email;
            this.check_mobile = response.data.data.check_mobile;

            /* check if it's all selected or not sms - email - notification */
            this.bulkEmail = true;
            this.bulkSms = true;
            this.bulkNotification = true;

            this.items.forEach(item => {
              if (item.email == false) {
                this.bulkEmail = false;
              }
            });

            this.items.forEach(item => {
              if (item.sms == false) {
                this.bulkSms = false;
              }
            });
            this.items.forEach(item => {
              if (item.Notification == false) {
                this.bulkNotification = false;
              }
            });
          }
        });
    },
    setPaginationParameters(response) {
      this.length = response.data.data.events.last_page;
      this.totalItems = response.data.data.events.total;
      if (this.length > 5) this.totalVisible = 5;
    }
  },
  mounted: function() {
    this.getData(false);
    axios.get(this.getApiUrl + "/getCountries").then(response => {
      this.nationalities = response.data.data;
    });
    axios.get(this.getApiUrl + "/getCodes").then(response => {
      this.Codes = response.data.data;
    });
    axios.get(this.getApiUrl + "/allRoles").then(response => {
      this.roles = response.data.data;
    });

    this.getUserNotification();
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";
.main-color-background {
  background-color: #e3e7f3;
}
.dark-color {
  color: #646464 !important;
}
.TabBtnActive {
  font-weight: bold;
  margin: 1px;
  background: rgba(255, 255, 255, 0.5) !important;
  // border-top: 2px solid #b9cbff !important;
  // border-bottom: 2px solid #b9cbff !important;
  color: #7297ff !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.TabBtn {
  margin: 1px;
  // border-top: 2px solid #bbbbbb !important;
  // border-bottom: 2px solid #bbbbbb !important;
  background: transparent !important;
  color: #bbbbbb !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
// should apply on all views
#wrapper {
  transition: all 0.4s ease 0s;
}
.v-application p {
  margin-bottom: 0px !important;
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

#wrapper.active {
  // padding-left: 210px;
}
.viewData {
  margin-left: 5px !important;
}
.editData {
  margin-left: 5px !important;
  margin-top: 12px !important;
}
.custom-file {
  width: 70%;
  border: 2px dashed #bbbbbb;
  box-shadow: unset !important;
  margin-left: -2px !important;
  .custom-file-label {
    box-shadow: unset !important;
    border: unset;
    background-color: unset !important;
    &::after {
      display: inline;
      padding: 3px 2;
      background-color: unset !important;
      color: #7297ff;
      margin-right: 36%;
    }
  }
}

.checkbox {
  margin-left: 7px !important;
}
.label {
  margin-top: 6%;
}
.logo-div-img {
  margin-top: 15%;
}
.change_password_div {
  // margin-top: 6%;
  background-color: #e3e7f3;
  border-bottom-right-radius: 25px;
  // padding: 0 !important;
  margin: auto;
  margin-right: -3px;
  margin-left: -5px;
  // width: 666px;

  // border-radius: 3%;
}
.submit-div-password {
  margin-top: -3%;
}
.old_password {
  margin-top: 4%;
}
// #info-div {
//   padding: 0 !important;
//   // margin: auto;
// }
</style>
